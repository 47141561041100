.main{
    text-align: center;
    padding: 2rem;
    background-color: #FFF9ED;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh; 
    box-sizing: border-box;
    overflow-y: auto;
    z-index: 1;
    height: 100vh; 
}
.innerMenu{
    border: 0.3rem solid #A6856D;
    border-radius: 8px;
    padding: 1rem;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.menuContainer{
    align-items: flex-start;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap; 
    width: 91%;
    margin-bottom: 1rem;
}
.menuColumn{
    flex: 1;
    text-align: left;
    padding-left: 1rem;
    padding-right: 1rem;
}
@media (max-width: 600px) {
    .menuContainer {
      flex-direction: column;
    }
  
    .menuColumn {
      flex: none; 
      width: 100%;
      width: -moz-available;          /* WebKit-based browsers will ignore this. */
      width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
      width: fill-available;
    }
  }
.touchableOpacity{
    border: none;
    border-radius: 6px;
    background: #FFF9ED;
    border: none;
    outline: none;
    cursor: pointer;
    margin-top: 2rem;
}
.backLogo{
    height: auto;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    max-height: 100px;
    width: 140px;
}
.itemHeader {
    font-family: "Poppins", sans-serif;
    color: #A6856D;
    font-size: 24px;
    text-transform:uppercase;
    font-weight: 600;
    margin-bottom: 9px;
    margin-top: 3rem;
}
.itemSubHeader {
    font-family: "Fraunces", serif;
    color: #C8A287;
    font-size: 18px;
    font-weight: 400;
    margin-top: -9px;
    margin-bottom: 9px;
}
.itemTitle {
    font-family: "Fraunces", serif;
    color: #4D3F28;
    font-size: 18px;
    font-weight: 600;
    margin-top: 8px;
    margin-bottom: 0px;
}
.price {
    font-family: "Fraunces", serif;
    color: #4D3F28;
    font-size: 18px;
    font-weight: 700;
    margin-top: 8px;
    margin-bottom: 0px;
}
.itemSubTitle {
    font-family: "Fraunces", serif;
    color: #C8A287;
    font-size: 16px;
    margin-top: 4px;
    font-weight: 400;
    margin-bottom: 0px;
}
.itemSubTitleDark {
    font-family: "Fraunces", serif;
    color: #A6856D;
    font-size: 16px;
    margin-top: 4px;
    font-weight: 800;
    margin-bottom: 0px;
}
.itemSubTitleDarkest {
    font-family: "Fraunces", serif;
    color: #4D3F28;
    font-size: 16px;
    margin-top: 4px;
    font-weight: 800;
    margin-bottom: 0px;
}
.italicText {
    font-style: italic;
}
.itemHeaderBox {
    display: flex;
}
.dottedDataSeparator {
    flex: 1;
    border-bottom: 3px dotted; /* style as necessary */
    position: relative;
    top: -4px; /* reposition as necessary */
}
.innerContainer{
    align-items: flex-start;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap; 
}
.innerColumn1,.innerColumn2{
    flex: 1;
    text-align: left;
}
.innerColumn1 {
    margin-right: 0.5rem;
}
@media (max-width: 600px) {
    .innerContainer {
      flex-direction: column;
    }
  
    .innerColumn1,.innerColumn2 {
      flex: none; 
      width: 100%;
      width: -moz-available;          /* WebKit-based browsers will ignore this. */
      width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
      width: fill-available;
    }
  }
.footer{
    margin-top: 1rem;
}