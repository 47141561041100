.companyLogo{
        height: auto;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        max-height: 200px;
        max-height: 130px;
        width: 161px;
}
.firstPageMenuButton{
        height: auto;
        border-radius: 6px;
        height: 42px;
        width: 161px;
        font-family: "Poppins", sans-serif;
        color: #C8A287;
        background-color: white;
        font-weight: 500;
        font-size: 16px;
        border: none;
        cursor: pointer;
}
.pageContainer{
        align-items: flex-start;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap; 
        width: 100%;
        height: '800px',
    }
    .pageColumn{
        flex: 1;
        text-align: left;
        width: 100%;
        height: 100%;
        align-self: center;
    }

.touchableOpacity{
  border: none;
  border-radius: 6px;
  border: none;
  outline: none;
  cursor: pointer;
}

.secondPageLeftText {
  font-family: "Fraunces", serif;
    color: #C8A287;
    font-size: 48px;
    font-weight: 400;
    padding-right: 4%;
    padding-left: 4%;
}
.secondPageRightText{
  padding-right: 4%;
  padding-left: 4%;
}
.pageHeader {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #4D3F28;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.pageDescription {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #4D3F28;
  margin-top: 1rem;
  font-weight: 300;
}
.thirdPageText{
  padding: 4% 8%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}
.thirdPageTitle{
  font-family: "Fraunces", serif;
  color: #C8A287;
  font-size: 24px;

}
.thirdPageDescription{
  font-family: "Fraunces", serif;
  color: #C8A287;
  font-size: 22px;
  font-weight: 600;
  margin-top: 2rem;
}
.thirdPageMenuButton{
  border-radius: 4px;
  font-family: "Fraunces", serif;
  color: #C8A287;
  background-color: white;
  font-weight: 500;
  font-size: 16px;
  border: 2px solid #C8A287;
  cursor: pointer;
  padding-right: 2rem;
  padding-left: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 4%;
}
.thirdPageContainer{
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; 
  width: 100%;
  height: '600px',
}
.thirdPageColumn, .thirdPageImage{
  flex: 1;
  text-align: left;
  width: 100%;
  height: 100%;
  align-self: center;
}
.fifthPageTitle{
  font-family: "Fraunces", serif;
  color: #C8A287;
  font-weight: 500;
  font-size: 60px;
  text-align: left;
  padding: 0 4.5%;
  margin: 5% 0;
}
.fifthPageContainer{
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; 
  height: '800px';
  margin: -2% 5%;
  width: '90%';
}
.addressColumn{
  width: 14rem;
}
.addressTitle{
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  color: #4D3F28;
  font-weight: 600;
  margin-bottom: 0;
}
.address {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: #4D3F28;
  font-weight: 400;
}
.fifthPageOpen{
  font-family: "Fraunces", serif;
  color: #C8A287;
  font-size: 22px;
  text-transform: uppercase;
  text-align: left;
}
.logoColumn{
  flex: 1 1;
  text-align: right;
  width: 100%;
  height: 100%;
  align-self: center;
}
.redirectLogo {
  width: 3.5rem;
  margin-left: 1rem;
}

@media (max-width: 600px) {
  .pageContainer {
    flex-direction: column;
  }

  .pageColumn {
    flex: none; 
    width: 100%;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;
  }
  .logoColumn{
    text-align: left;
    flex: none; 
    width: 100%;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;
  }
  .redirectLogo {
    margin-left: 0;
    margin-right: 1rem;
  }
}

@media (max-width: 1200px) {
  .thirdPageContainer {
    flex-direction: column;
  }

  .thirdPageImage{
    flex: none; 
    width: 100%;
    height: 40rem;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;
  }

  .thirdPageColumn {
    flex: none; 
    width: 100%;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;
  }
}

@media (max-width: 700px) {

  .thirdPageImage{
    height: 100%;
  }

  .fifthPageContainer {
    flex-direction: column;
  }

  .addressColumn {
    flex: none; 
    width: 100%;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;
  }
}
